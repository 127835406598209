import React from 'react'
import Layout from '../layouts'
import { Link } from 'gatsby'

const NotFoundPage = () => (
	<Layout
		pathname="/404/"
		head={{
			name: '404 NOT FOUND',
			meta: {
				description:
					'404 NOT FOUND Oops! We were unable to find what you were looking for.',
			},
		}}
	>
		<div className="container py-5">
			<div className="row">
				<div className="col-md-12">
					<center>
						<h1>404 NOT FOUND</h1>
						<h2>Oops!</h2>
						<p>We were unable to find what you were looking for.</p>
						<Link
							className="btn btn-primary my-3 blue-btn-arrow"
							to="/"
							title="Home"
						>
							Go to the homepage
						</Link>
					</center>
				</div>
			</div>
		</div>
	</Layout>
)

export default NotFoundPage
